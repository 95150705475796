.components {
    padding: 0 16px;

    .title {
        text-align: center;
        max-width: fit-content;
    }

    .space {
        height: 20px;
    }

    .ribbon {
        top: 0px;
        right: 0px;
        max-width: min-content;
        display: flex;

    }
    .card {
        width: fit-content;
        background-color: "gray";
        
        &.w-full {
            width: 100%;
        }
    }
}