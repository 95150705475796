.product-list-box {
    display: flex;
    flex-direction: row;
    gap: 5;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;

    .product-list-img {
        width: 30%;
        padding-left: 12px;
        padding-right: 16px;

        .ant-image-mask {
            border-radius: 6px;
        }
    }

    .product-list-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        text-align: left;
        text-transform: uppercase;
        gap: 0px;

        .product-list-text {
            font-size: 14px;
            font-weight: 600;
            color: #383E42;

            &.name {
                max-width: 80%;
                margin-top: 0px;
            }
        }

        .product-list-brand {
            font-size: 12px;
            font-weight: 400;
            color: #797C81;
            margin-bottom: 6px;
            margin-top: -2px;
        }
    }

}