.action-tile-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    gap: 0.5rem;
    border-radius: 8px;
    position: relative;
    max-width: max-content;
    background-color: #fff;

    .badge {
        font-size: 0.75rem;
        position: absolute;
        top: 0.5rem;
    }

    .outer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        &.square {
            border-radius: 0.5rem;
            background: "#fff";
        }

        .icon {
            font-size: 2rem;
            background-color: white;
            border: 1px solid #f0f0f0;
            max-width: min-content;
            padding: 1rem;
            border-radius: 10em;
            display: flex;
            color: #9b9b9b;
            box-shadow: 0px 6px 10px 0px #B1954114;
            cursor: pointer;

            &.fixd-25 {
                min-width: 25px;
                min-height: 25px;
                max-width: 25px !important;
                max-height: 25px;
                justify-content: center;
            }

            &.noborder {
                border: none;
                padding: 0.3rem;
                box-shadow: none;
            }

            &.left {
                justify-content: flex-start;
                text-align: left;
                padding: 0;
                margin-right: auto;
            }

            &.center {
                justify-content: center;
                text-align: center;
            }

            &.right {
                justify-content: flex-end;
                text-align: right;
                padding: 0;
                margin-left: auto;
            }

        }

        .title {
            font-size: 12px;

            &.min-2 {
                min-height: calc(1.2em * 2);
            }

            &.left {
                text-align: left;
            }

            &.center {
                text-align: center;
            }

            &.right {
                text-align: right;
            }
        }
    }

    .child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
    }
}