.payment-methods-container {
  max-width: 600px;
  margin: 0 auto;

  .payment-header {
    margin-bottom: 20px;
    text-align: left;
    background-color: #0756df;
    padding: 24px;
    display: flex;
    flex-direction: column;

    span {
      color: #fff;
      font-size: 22px;
    }

    .to-pay {
      font-size: 26px;
    }

    .inv {
      margin: 0px;
      font-size: 12px;
      font-weight: 400;
      opacity: 0.6;
    }

    .ant-card-body {
      padding: 0px 24px;
      text-align: left;
      margin-bottom: 16px;
    }

    h4 {
      margin-bottom: 0;
    }

    h3 {
      margin-top: 10px;
    }
  }

  .payment-options {
    margin: 24px;

    .ant-card-body {
      padding: 0px;
    }

    .ant-card-head-title {
      font-size: 14px;
      font-weight: bold;
    }

    .payment-method-item {
      cursor: pointer;
      transition: background-color 0.3s;
      padding: 12px 24px;

      .anticon {
        svg {
          fill: #0756df;
        }
      }

      &:hover {
        background-color: #f0f0f0;
      }

      .ant-list-item-meta-avatar {
        font-size: 24px;
      }

      .ant-list-item-meta-title {
        font-weight: bold;
      }

      .ant-list-item-meta-description {
        font-size: 12px;
      }
    }
  }
}