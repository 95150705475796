* {
  color: #383E42;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.pointer {
  cursor: pointer;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.layout {
  min-height: 100vh;
  background-color: #fff;
  max-width: 420px;
  margin: auto;
  overflow: hidden;
  position: relative;

  @media (max-width: 420px) {
    &.bg-gray{
      background-color: #f5f5f5;
    }
  }
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.m-auto {
  margin: auto;
}

.space {
  height: 20px;
}


body {
  background-color: #f5f5f5;
  margin: 0px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}