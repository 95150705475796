.with-image-card {
    cursor: pointer;

    .with-image {
        .img {
            border-radius: 10px;
            display: block;
        }

        .meta {
            position: absolute;
            bottom: 40px;
            left: 30px;

            .title {
                color: #fff;
            }

            .ant-card-meta-title {
                margin-bottom: 0px;
                font-size: 12px;
            }

            .ant-card-meta-description {
                color: #fffffff6;
                font-size: 10px;
            }

            &.with-left-line {
                border-left: "2px solid #eee";
                margin-left: 8px;
                padding-left: 5px;
            }
        }

        .shadow {
            position: absolute;
            background: linear-gradient(0deg, #000000 30%, rgba(0, 0, 0, 0) 100%);
            border-radius: 10px;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            pointer-events: none;
        }

        .image-box {
            position: relative;
            display: inline-block;
        }
    }
}

.travel-card {
    width: 100%;

    .top {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        align-items: center;

        .time {

            .via {
                font-size: 12px;
                line-height: 18px;
            }

            .time {
                font-size: 16px;
                line-height: 24px;
            }

            &.left {
                text-align: left;
            }

            &.right {
                text-align: right;
            }
        }

        .middle {
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .middle {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        text-align: center;
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .name {
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
        }

        .price {
            font-size: 18px;
            line-height: 27px;
            font-weight: 800;
        }
    }
}

.pack-card {
    width: 100%;

    .top {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        align-items: center;
    }

    .pack-head {
        .top {
            align-items: flex-start;
        }

        .pack-title {
            white-space: nowrap
        }

        .pack-subtitle {
            font-size: 12px;
            font-weight: 400;
            white-space: nowrap;
        }

        .price {
            font-size: 18px;
            line-height: 27px;
            font-weight: 800;
        }
    }

    .point-box {
        margin-top: 10px;

        .point {
            display: flex;
            gap: 8px;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;

            .left {
                font-weight: 500;
                min-width: 100px;
            }

            .right {}
        }
    }

    .pos-right {
        margin-left: auto;
    }

}


.product-card {
    margin-bottom: 16px;
    border-radius: 8px;
    border: 1px solid #C9CACD;

    .title {
      margin-bottom: 16px;

      .ant-typography {
        margin-bottom: -5px;
        font-size: 16px;
        margin-top: 0px;
      }

      .reco {
        font-size: 12px;
      }
    }

    .product {
      .description {
        color: #797C81;
      }

      .view-benefits {
        color: #3984DA;
      }
    }
  }