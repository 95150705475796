@import '../scss/variables.scss';

.payswift-home {
  .content {
    padding: 16px;
  }

  .section-title {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .ant-col {
    display: flex;
    justify-content: center;
  }
}