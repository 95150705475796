.insurance-page {
  min-height: 100vh;

  @media (max-width: 420px) {
    background-color: #f5f5f5;
  }

  .content {
    background: #fff;

    h2 {
      padding-left: 24px;
      padding-right: 24px;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: -8px !important;
      margin-top: 32px !important;

      @media (max-width: 420px) {
        padding-left: 16px !important;
        padding-right: 16px !important;
      }

    }

    .insurance-grid {
      padding: 24px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      margin-bottom: 24px;

      @media (max-width: 420px) {
        padding: 16px;
        margin-top: 16px;
        gap: 8px;
      }

      @media screen and (max-width: 360px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .insurance-card {
        text-align: center;

        img {
          width: 40px;
          height: 40px;
          margin-bottom: 8px;
        }

        p {
          margin: 0;
          font-size: 14px;
        }
      }
    }

    .car-insurance-banner {
      img {
        width: 100%;
      }
    }
  }
}

.plan-selection {
  padding: 24px;
  padding-bottom: 80px;
  margin-bottom: 40px;

  .title {
    margin-top: 0px !important;
    margin-bottom: 16px;
  }

  .next-button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 14px 0;

    @media screen and (max-width: 420px) {
      background-color: #fff;
    }

    .next-button {
      background: #0856DF;
      color: #fff;
      padding: 16px 48px;
      border: none;
      border-radius: 6px;
      font-size: 18px;
      cursor: pointer;
      width: calc(100% - 48px);
      max-width: 372px;
      margin: 0 auto;

      &.disabled {
        background-color: #B7B7B7;
        cursor: not-allowed;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}



.fill-details {
  .container {

    .ant-form-item-required {
      flex-direction: row-reverse;
    }

    .ant-form-item-required::before {
      margin-left: 4px;
    }

    .ant-form-item-required::after {
      width: 0;
      margin: 0 !important;
    }

    .ant-input {
      border: 1px solid #757D8A;
    }

    .personal-details-form {

      .ant-col.ant-col-16 {
        max-width: 100% !important;
      }

      .spouse-details,
      .child-details {
        padding: 2rem;
        border-top: 8px solid #f5f5f5;
        padding-top: 1rem;
      }

      .personal-details {
        padding: 2rem;
        padding-top: 0px !important;

        .title {
          font-size: 18px;
          font-weight: 600;
          ;
        }
      }
    }


    .submit-button {
      margin-top: 2rem;
      text-align: center;
      background-color: #F5F5F5;
      gap: 30px;
      display: flex;
      flex-direction: column;

      .ant-form-item {
        margin: 24px;
      }

      .add-buttons {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        gap: 16px;

        .add-spouse,
        .add-child {
          flex-grow: 1;
          border: 0.25px solid #C9CACD;
          padding: 12px 16px 12px 16px;
          background-color: #fff !important;
          height: 45px;
          max-width: 150px;
          padding: 12px 16px 12px 16px;
          border-radius: 8px;
          cursor: pointer;

          .plus {
            color: #0856DF;
            margin-left: 18px;
          }

          &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }

      .make-payment {
        width: 100%;
        background-color: #0856DF;
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 700px;
        cursor: pointer;
        height: 40px;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }

}

.cart-page {
  background-color: #fff;

  .added-heading {
    margin: 20px 0;
  }

  .selected-plan {
    padding: 16px;
  }

  .covered-members {
    margin-top: 16px;
    padding: 16px;
    background-color: #ffffff;
    border-top: 8px solid #f5f5f5;

    svg {
      fill: #0856DF;
    }

    .covered-heading {
      font-weight: 600;
      font-size: 18px;
    }

    .edit-link {
      float: right;
      color: #0856DF;
    }

    .members-heading {
      color: #797C81;
    }
  }

  .total-section {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #ffffff;
    border-top: 1px solid #e8e8e8;
    position: absolute;
    bottom: 0px;
    width: calc(100% - 32px);

    .price {
      font-weight: bold;
      font-size: 18px;

      .gst {
        font-weight: 400;
        font-size: 16px;
      }
    }
  }

  .proceed-button {
    background-color: #0856DF;
    color: #ffffff;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    height: 40px;
    padding: 8px 24px 8px 24px;
    gap: 10px;
    border-radius: 4px;
    border: none;

    &:hover {
      background-color: #40a9ff;
      opacity: 0.9;
    }
  }
}

.payment-success {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 0 20px;

  .ant-space {
    text-align: center;
    width: 100%;
    row-gap: 4px;
  }

  .ant-typography {
    margin-bottom: 0;
  }

  .ant-space-item {
    width: 100%;
  }

  .go-home {
    background-color: #0856DF;
    height: 40px;
    gap: 10px;
    border-radius: 4px;
    color: #fff !important;
    border: none;
    width: 100%;
    cursor: pointer;
    margin-top: 50px;

    &:hover {
      background-color: #40a9ff;
      opacity: 0.9;
    }
  }
}

.policy-page {
  background-color: #fff;
  padding: 24px;
}
