.shp {
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  border-bottom: 8px solid #f5f5f5;

  .subtitle {
    font-size: 16px;
    color: #6b7280;
    margin-bottom: 8px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 16px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .covered-info {
    display: flex;
    align-items: center;
    justify-content: center;

    .avatars {
      display: flex;
      align-items: center;
      margin-right: 8px;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-left: -10px;
        border: 2px solid #fff;
      }
    }

    .covered-text {
      font-size: 16px;
      color: #4b5563;

      strong {
        font-weight: 600;
        color: #111827;
      }
    }
  }
}


.benefit-drawer {
  .ant-drawer-content-wrapper {
    width: 400px !important;
  }

  .ant-drawer-header {
    border-bottom: 1px solid #e8e8e8;
  }

  .ant-drawer-body {
    padding: 24px;

    h3 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    ul {
      list-style-type: disc;
      padding-left: 20px;

      div {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
  }

  .drawer-tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    border-bottom: 1px solid #e8e8e8;

    .drawer-tab {
      background-color: transparent;
      border: none;
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      transition: color 0.2s;
      color: #6b7280;

      &.active {
        color: #000;
        border-bottom: 2px solid #1890ff;
      }
    }
  }

  .drawer-content {
    margin-bottom: 20px;
  }

  .drawer-note {
    background-color: #E3EFFF;
    padding: 18px;
    border-radius: 4px;
    margin-bottom: 20px;

    .info-icon{
      color: #1890ff;
      margin-right: 4px;
    }

    p {
      font-size: 14px;
      color: #666;
      margin: 0;
    }
  }
}