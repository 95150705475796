@import '../../scss/variables.scss';

.header {
  background-color: #fff;
  // position: fixed;
  // left: 0;
  // top: 0;
  // right: 0;
  padding: 20px;
  border-bottom: 1px solid #dedede;
  z-index: 1;
  max-height: 20px;
  display: flex;


  .ant-layout-sider.closed {
    transform: translateX(-250px);
    /* hide the sidebar when closed */
  }


  .logo {
    font-size: 16px;
    color: $color-red;
    display: inline-block;
  }

  .text {
    font-size: 12px;
    color: #333;
    display: inline-block;
  }

  .menu-icon {
    cursor: pointer;

    &:hover {
      opacity: 0.4;
      transition: 0.4s;
    }
  }

  .child {
    display: inline-block;
  }

  .header-content {
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-top: 4px;

    .back-button{
      margin-right: 16px;
      cursor: pointer;

      &:hover {
        opacity: 0.4;
        transition: 0.4s;
      }
    }

    &.with-back{
      margin-left: 0;
    }

    button {
      margin-right: 10px;
    }

    h1 {
      margin: 0;
      font-size: 18px;
    }
  }
}


.sidebar {
  background: #fff;
  padding: 40px 0;
  padding-top: 0px;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: absolute;
  z-index: 1;
  min-height: 100vh !important;
  left: 0;
  top: 0;
  bottom: 0;


  h1 {
    margin: 0;
    font-size: 18px;
  }


  .sidebar-toggle {
    position: fixed;
    top: 70px;
    left: 20px;
    z-index: 2;
  }

  .profile {
    padding: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    background: #FAFAFA;


    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px
    }

    h2 {
      margin: 0;
      font-size: 18px;
    }

    a {
      font-size: 14px;
      color: #1890ff;
    }

    img {
      margin-left: 20px;
    }
  }

  .version {
    position: relative;
    margin-top: 50px;
    left: 20px;
    font-size: 12px;
    color: #999;
  }
}

.backstrip {
  width: 100%;
  height: 40px;
  padding: 4px 24px 4px 24px;
  gap: 10px;
  display: flex;
  align-items: center;
  background-color: #E3EFFF;
  color: #227AE5;
  cursor: pointer;

  svg{
    fill: #227AE5;
  }
}