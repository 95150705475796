.section {
    width: 100%;

    .section-title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: "#383E42";
        margin: 16px 0px 16px 0px;
    }
}