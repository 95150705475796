.insurance-embed-box {
    padding: 24px;

    .head {
        gap: 20px;

        .heading {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }

        .embed-subtitle {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;

            span {
                font-weight: 600;
            }
        }

    }

    .body {
        gap: 20px;
        margin-top: 24px;

        .heading {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
        }

        .list {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
        }

        .bullet-points {
            margin-left: -20px;

            .read-more {
                cursor: pointer;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                margin-top: 8px;
                color: #502F88
            }
        }
    }
}